<template src="./AnnouncementExternal.html"></template>

<script>
import ArrobaMedellinFooter from "@/components/ArrobaMedellinFooter/ArrobaMedellinFooter";
import ArrobaMedellinAppBarExternal from "@/components/ArrobaMedellinAppBarExternal/ArrobaMedellinAppBarExternal";
import SearchIcon from "@/components/Icons/SearchIcon";
import announcementService from "../../services/Announcement";
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";

export default {
  name: "AnnouncementExternal",
  components: {
    ArrobaMedellinFooter,
    ArrobaMedellinAppBarExternal,
    SearchIcon,
    ArrobaMedellinArrobitaNoData,
  },
  props: {
    TitleNotFound: {
      type: String,
      default: "Convocatorias externas",
    },
    notFound: {
      type: String,
      default: "No hay convocatorias externas en éste momento",
    },
  },
  data() {
    return {
      searchWord: "",
      errorMessage: "",
      announcements: [],
    };
  },
  created() {
    this.getAnnouncementExternal();
  },
  methods: {
    async getAnnouncementExternal() {
      try {
        this.announcements =
          await announcementService.announcementExternalByState(true);
      } catch (error) {
        console.error("Error get announcements external", error);
      }
    },
    async search() {
      this.announcements = [];
      try {
        if (this.searchWord) {
          this.announcements =
            await announcementService.getSearchAnnouncementExternal({
              word: this.searchWord,
              take: 6,
              skip: 0,
            });
          if (!this.announcements.length) this.errorMessage = true;
        } else {
          this.getAnnouncementExternal();
        }
      } catch (e) {
        console.error(e);
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Ocurrio un error.",
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./AnnouncementExternal.scss"></style>